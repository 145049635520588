import firebase from "@/firebase";

const state = {
    userProfile: {},
    loggedIn: false, // default false
    tokens: null
};

const getters = {
    // trying to get the information from the current state

    // this one is returning the userProfile info in the state
    userProfile: ({ userProfile }) => userProfile,

    // simple are they logged in or not
    loggedIn: ({ loggedIn }) => loggedIn,

    getTokens(state) {
        return state.tokens;
    }
};

const mutations = {
    // After user clicks login, firebase will
    // have info in its object that we need to actually
    // collect in our store. So here, we are changing the
    // state. Refrenced in auth.js

    // takes in state and parameter as defined in auth.js
    USER_DETAILS(state, userProfile) {
        state.loggedIn = true;
        // console.log('before', userProfile);
        state.userProfile = {
            name: userProfile.displayName,
            picture: userProfile.photoURL,
            email: userProfile.email,
            phone: userProfile.phoneNumber,
            id: userProfile.uid
        };
        const userJSON = JSON.parse(JSON.stringify(userProfile));
        var tokens = userJSON['stsTokenManager'];
        state.tokens = tokens;
    },
    LOGOUT(state) {
        // remove all info from the current state
        state.loggedIn = false;
        state.userProfile = {};
        state.tokens = null;
    }

};

const actions = {
    async login(store) {
        if (store.state.loggedIn) return; // don't log in if logged in

        // otherwise try to log in
        const provider = new firebase.auth.GoogleAuthProvider();

        try {
            await firebase.auth().signInWithRedirect(provider);
        } catch (error) {
            console.log(error);
        }

    },
    async logout() {
        // implement the actual firebase logout function
        try {
            await firebase.auth().signOut();
        } catch (error) {
            console.log(error);
        }

    }
};

export default {
    namespaced: true, // part of vue, modular
    state,
    getters,
    mutations,
    actions
};