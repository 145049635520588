import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueParticles from 'vue-particles';
import VueTyper from 'vue-typer';
import Vuelidate from 'vuelidate';
import './auth';
// import vClickOutside from 'v-click-outside';
// import vOutsideEvents from 'vue-outside-events'

Vue.config.productionTip = false;
Vue.prototype.$webserver = 'https://catalyst-tutors.com:8000' // 'http://localhost:5000' // 'http://159.65.45.211:8000';

// Vue.use(vOutsideEvents)
// Vue.use(vClickOutside)
Vue.use(Vuelidate)
Vue.use(VueParticles)
Vue.use(VueTyper)


new Vue({
    router,
    store,
    vuetify,
    theme: { dark: true },
    render: (h) => h(App),
}).$mount("#app");
