import Vue from "vue";
import Vuex from "vuex";
import user from '@/store/user'
import form from '@/store/form'

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        user: user,
        form: form
    }
});

export default store;