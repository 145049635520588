<template>
    <v-app-bar app fixed flat color="rgba(0,0,0,0)">
        <v-toolbar-title>
            <v-btn text to="/">
                <img
                    class="mr-3"
                    :src="require('../assets/catalyst_logo.png')"
                    height="35"
                />

                <h1>Catalyst</h1>
            </v-btn>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            text
            color="#AAA"
            v-for="(link, index) in links"
            :key="index"
            :to="link.url"
        >
            <v-icon>{{ link.icon }}</v-icon>
            <span class="hidden-sm-and-down" style="padding-right: 5px"></span>
            <div class="hidden-sm-and-down">{{ link.label }}</div>
        </v-btn>
    </v-app-bar>
</template>

<script>
    export default {
        data() {
            return {
                is_small: false,
            };
        },
        props: {
            links: Array,
        },
    };
</script>

<style lang="scss" scoped>
    h1 {
        color: rgba(200, 200, 200, 0.8);
    }
</style>
