<template>
    <div class="background-area">
        <vue-particles
            color="#444"
            :particleOpacity="0.7"
            :particlesNumber="80"
            shapeType="polygon"
            :particleSize="6"
            linesColor="#444"
            :linesWidth="3"
            :lineLinked="true"
            :lineOpacity="0.4"
            :linesDistance="180"
            :moveSpeed="0.5"
            :hoverEffect="true"
            hoverMode="grab"
            :clickEffect="false"
            clickMode="push"
        ></vue-particles>
    </div>
</template>

<script>
    export default {
        data() {
            return {};
        },
    };
</script>

<style lang="scss" scoped>
    * {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: #000;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
    }

    .background-area {
        // height: 101vh;
        background-color: black;
        // background-image: url("../assets/menu-background.jpg");
        // filter: blur(10px);
        // transform: scale(1.1);
        // display: block;
        // -webkit-filter: blur(10px);
        // backdrop-filter: blur(10px);
    }
</style>
