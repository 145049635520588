import axios from 'axios';

function formatTime(date) {
    var tzDate = new Date(date.toLocaleString('en-US', {
        timeZone: 'America/New_York'
    }));
    const formatedMin = (tzDate.getMinutes() < 10 ? '0' : '') + tzDate.getMinutes();
    var timeString = tzDate.getHours() + ':' + formatedMin;

    // console.log('Formatted string', timeString);

    return timeString
}

function addMinutes(dt, minutes) {
    return new Date(dt.getTime() + minutes * 60000);
}

/* function addWeeks(dt, weeks) {
    return new Date(dt.setDate(dt.getDate() + weeks * 7));
} */

// calculate current events
/* function getNewEventsPreview(classList, lastDate, repeatWeekly) {
    var newEvents = [];

    for (var i = 0; i < classList.length; i++) {
        var scheduledClass = classList[i];
        var event = scheduledClass.event;
        if (!event) {
            continue;
        }

        var startDate = event.start;
        var endDate = event.end;

        // add the current event as is
        console.log(scheduledClass);

        var newEvent = {
            start: startDate,
            end: endDate,
            name: "Preview",
            color: "rgba(200,200,200,.9)",
            description: "Preview"
        }
        newEvents.push(newEvent);

        // if we're not repeating weekly, then continue
        if (!repeatWeekly || !lastDate) {
            continue;
        }

        // otherwise, create duplicates
        var currentStartDate = addWeeks(currentStartDate, 1);
        var currentEndDate = addWeeks(currentEndDate, 1);
        while (currentStartDate <= endDate) {
            var newWeeklyEvent = JSON.parse(JSON.stringify(newEvent))
            // create new event object
            newWeeklyEvent.start = currentStartDate;
            newWeeklyEvent.end = currentEndDate;

            // push event
            newEvents.push(newWeeklyEvent);

            // increment
            currentStartDate = addWeeks(currentStartDate, 1);
            currentEndDate = addWeeks(currentEndDate, 1);
        }

    }
} */

const state = {
    timeIdx: -1,
    userName: '',
    userEmail: '',
    userID: '',
    userIP: '',
    studentInfo: [],
    classTypeSelect: '',
    sessionSelect: '',
    classSelect: null,
    repeatWeekly: null,
    repeatLastDate: null,
    classTimeInfo: [],
    fullSessionType: '',
    newEventsPreview: [],
    additionalInfo: '',
    tokens: {},

    // calendar state
    allEvents: []
};

const getters = {
    getState(state) {
        return state;
    },
    getFormState(state) {
        var formState = {
            username: state.userName,
            userEmail: state.userEmail,
            userID: state.userID,
            userIP: state.userIP,
            studentInfo: state.studentInfo,
            classTypeSelect: state.classTypeSelect,
            sessionSelect: state.sessionSelect,
            classSelect: state.classSelect,
            durationSelect: state.durationSelect,
            timeSelect: state.timeSelect,
            // these will be used to generate below
            repeatWeekly: state.repeatWeekly,
            repeatLastDate: state.repeatLastDate,
            classTimeInfo: state.classTimeInfo,
            // get new events
            newEventsPreview: state.newEventsPreview,
            fullSessionType: state.fullSessionType,
            additionalInfo: state.additionalInfo,
            tokens: state.tokens,
        }
        return formState;
    },
    getDates(state) {
        // console.log('GETTING DATE!', state.selectedDate);
        return state.selectedDates;
    },
    getSlots(state) {
        return state.selectedSlots;
    },
    getEvents(state) {
        return state.selectedEvents;
    },
    getTimeIdx(state) {
        return state.timeIdx;
    },
    getClassTimeInfo(state) {
        return state.classTimeInfo;
    },
    getAllEvents(state) {
        return state.allEvents;
    }
};

const mutations = {
    SET_DATE(state, event) {
        // console.log('SETTING DATE!', event.start)
        var idx = state.timeIdx;

        var parsedStartDate = new Date(event.start)
        var parsedEndDate = new Date(event.end)

        var slotStr = parsedStartDate.toDateString() + ' (' +
            formatTime(parsedStartDate) + '-' + formatTime(parsedEndDate) + ')';
        state.classTimeInfo[idx].date = slotStr; // reset values
        state.classTimeInfo[idx].time = '';
        state.classTimeInfo[idx].duration = '';
        state.classTimeInfo[idx].slot = event;

        // calculate the respective available time slots
        // console.log('CALCULATING AVAIL TIMES');
        const minTime = 30; // minutes
        var availableStartTimes = [];
        var availableDurations = {};
        var currentDate = parsedStartDate;
        while (currentDate < parsedEndDate) {
            const strStartTime = formatTime(currentDate);

            // find valid durations
            var durations = [30, 60];
            // console.log('SESSION SELECT', state.sessionSelect);
            if (state.sessionSelect.toLowerCase().includes('class')) {
                durations = [60];
            }

            var validDurations = [];
            for (var i = 0; i < durations.length; i++) {
                if (addMinutes(currentDate, durations[i]) <= parsedEndDate) {
                    validDurations.push(durations[i]);
                }
            }

            // increment first just in case
            currentDate = addMinutes(currentDate, minTime);

            // if there's no available time slots, don't list it.
            // console.log('VALID DURATIONS', validDurations)
            if (validDurations.length === 0) {
                // console.log('VALID DURATIONS TRIGGERED', validDurations)
                continue;
            }
            // otherwise include all the info
            availableStartTimes.push(strStartTime);
            availableDurations[strStartTime] = validDurations;

        }
        // console.log('AVAIL TIMES', availableStartTimes);
        state.classTimeInfo[idx].availableStartTimes = availableStartTimes;
        state.classTimeInfo[idx].availableDurations = availableDurations;
    },
    SET_FORM_STATE(state, formState) {
        state.userName = formState.userName;
        state.userEmail = formState.userEmail;
        state.userID = formState.userID;
        state.userIP = formState.userIP;
        state.studentInfo = formState.studentInfo;
        state.classTypeSelect = formState.classTypeSelect;
        state.sessionSelect = formState.sessionSelect;
        state.classSelect = formState.classSelect;
        // these will be used to generate below
        state.repeatWeekly = formState.repeatWeekly;
        state.repeatLastDate = formState.repeatLastDate;
        state.classTimeInfo = formState.classTimeInfo;
        // get new events
        state.newEventsPreview = []; // getNewEventsPreview(state.classTimeInfo, state.repeatLastDate, state.repeatWeekly)

        // ensure class time info is not over max
        if (state.sessionSelect && state.sessionSelect.toLowerCase().includes('class')) {
            state.classTimeInfo = state.classTimeInfo.slice(0, 1);
        }
        state.fullSessionType = formState.fullSessionType;
        state.additionalInfo = formState.additionalInfo;
        state.tokens = formState.tokens;
        axios.post(this._vm.$webserver + '/api/user/save_form', state);
    },
    SET_ADDITIONAL_INFO(state, info) {
        state.additionalInfo = info;
    },
    CLEAR_FORM(state) {
        state.userName = '';
        state.userEmail = '';
        state.studentInfo = [];
        state.classTypeSelect = '';
        state.sessionSelect = '';
        state.classSelect = '';
        state.durationSelect = '';
        state.timeSelect = '';
        state.repeatWeekly = '';
        state.repeatLastDate = '';
        state.classTimeInfo = [];
        state.additionalInfo = '';
    },
    SET_TIME_IDX(state, index) {
        // console.log('Setting time idx', index);
        state.timeIdx = index;
    },
    ADD_TIME_INFO(state, obj) {
        state.classTimeInfo.push(obj);
        state.timeIdx += 1;
        // console.log('Setting time idx', state.timeIdx);
    },
    REMOVE_TIME_INFO(state, index) {
        state.classTimeInfo.splice(index, 1);
        state.timeIdx -= 1;
        console.log('Setting time idx', state.timeIdx);
    },
    SET_ALL_EVENTS(state, allEvents) {
        state.allEvents = allEvents;
    }

};

const actions = {

};

export default {
    namespaced: true, // part of vue, modular
    state,
    getters,
    mutations,
    actions
};