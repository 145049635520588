import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
var request = require('sync-request');

// blocking but whatever.. just to initialize firebase on startup.
var respFull = request('GET', 'https://catalyst-tutors.com:8000' + '/api/firebase_keys');
var resp = JSON.parse(respFull['body']);

const firebaseKeys = {
    apiKey: resp["apiKey"],
    authDomain: resp["authDomain"],
    projectId: resp["projectId"],
    storageBucket: resp["storageBucket"],
    messagingSenderId: resp["messagingSenderId"],
    appId: resp["appId"],
    measurementId: resp["measurementId"]
};

firebase.initializeApp(
    firebaseKeys
);

// export so we can use it in our application
export default firebase;