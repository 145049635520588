import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("../views/Home.vue"),
		meta: {
			title: 'Catalyst Tutors'
		}
	},
	{
		path: "/about",
		name: "About",
		component: () => import("../views/About.vue"),
		meta: {
			title: 'Catalyst Tutors - About'
		}
	},
	{
		path: "/schedule",
		name: "Schedule",
		query: {
			form: ''
		},
		component: () => import("../views/Schedule.vue"),
		meta: {
			title: 'Catalyst Tutors - Schedule'
		}
	},
	{
		path: "/subjects",
		name: "Subjects",
		component: () => import("../views/Subjects.vue"),
		meta: {
			title: 'Catalyst Tutors - Subjects'
		}
	}
];

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
});

export default router;
