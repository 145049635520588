import firebase from '@/firebase';
import store from './store';
import axios from 'axios';

// https://firebase.google.com/docs/auth/web/manage-users
firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
        // console.log("auth.js USER LOGGED IN. ATTEMPTING STORE");
        //console.log('FIREBASE USER', user);
        //console.log('STRINGIFY', JSON.parse(JSON.stringify(user)));

        store.commit('user/USER_DETAILS', user); // mutation, gets details

        const userJSON = JSON.parse(JSON.stringify(user));
        var tokens = userJSON['stsTokenManager'];
        var sessionInfo = {
            "email": userJSON['email'],
            "user_id": userJSON['id'],
            "tokens": tokens
        }
        //console.log('Session info', sessionInfo);
        axios.post(this.$webserver + '/api/user/save_session', sessionInfo).then((response) => {
            console.log(response);
        });

    } else { // if logout is pressed
        store.commit('user/LOGOUT');
    }
});