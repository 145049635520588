<template>
    <v-app>
        <NavBar :links="links" />
        <Background />
        <transition name="fade" mode="out-in">
            <router-view />
        </transition>
    </v-app>
</template>

<script>
    import NavBar from './components/NavBar';
    import Background from './components/Background.vue';
    // https://vue-particles.netlify.app/

    export default {
        name: 'App',

        components: {
            NavBar,
            Background,
        },

        data: () => ({
            links: [
                {
                    label: 'About Me',
                    icon: 'mdi-account-circle',
                    url: '/about',
                },
                {
                    label: 'Subjects and Tuition',
                    icon: 'mdi-book-multiple',
                    url: '/subjects',
                },
                {
                    label: 'Schedule',
                    icon: 'mdi-calendar-plus',
                    url: '/schedule',
                },
            ],
        }),
    };
</script>

<style lang="scss">
    * {
        font-family: Raleway, Helvetica, Arial, Helvetica, sans-serif;
    }
    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease;
    }
    .fade-enter,
    .fade-leave-active {
        opacity: 0;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 1);
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgba(200, 200, 200, 0.5);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(200, 200, 200, 1);
    }
</style>
